import Head from 'next/head'
import Image from 'next/image'
import { Inter } from 'next/font/google'
import styles from '@/styles/Home.module.css'
import Link from "next/link";
import React from "react";

const inter = Inter({ subsets: ['latin'] })

export default function Home() {
  return (
    <>
      <Head>
        <title>Droid Studio</title>
        <meta name="description" content={`AIクリエイターを支援する総合プラットフォーム`} />
        <meta property="og:image" content='https://ai.taiyop.com/mainOGP.jpg' />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <main className={styles.main}>
        <div className='text-3xl'>
          Droid Studio
        </div>

        <Link href={`/love-is-war`} className='mx-3'>
          Love Is War
        </Link>
      </main>
    </>
  )
}
